import vue from 'vue'
import auth from '@/middleware/auth.js'

export default [
	{
		path: '',
		component: () => import('@/layouts/Constelacion'),
		children: [
			{
				path: '/fyc',
				name: 'fyc',
				component: () => import('@/apps/fyc/pages/Dashboard'),
				meta: {
					title: 'Financiamiento y Cofinanciamiento',
					middleware: auth
				}
			},
			{
				path: '/fyc/solicitudes',
				name: 'fyc_solicitudes',
				component: () => import('@/apps/fyc/pages/Solicitud'),
				meta: {
					title: 'Solicitudes - Financiamiento y Cofinanciamiento',
					middleware: auth
				}
			},
			{
				path: '/fyc/solicitudes/:id',
				name: 'fyc_solicitudes_visualizador',
				component: () => import('@/apps/fyc/pages/Solicitud/Visualizador'),
				meta: {
					title: 'Solicitudes - Financiamiento y Cofinanciamiento',
					middleware: auth
				}
			},
			{
				path: '/fyc/admin/solicitudes',
				name: 'fyc_admin_solicitudes',
				component: () => import('@/apps/fyc/pages/Admin/Solicitud'),
				meta: {
					title: 'Solicitudes - Financiamiento y Cofinanciamiento',
					middleware: auth
				}
			},
			{
				path: '/fyc/admin/alianzas',
				name: 'fyc_admin_alianzas',
				component: () => import('@/apps/fyc/pages/Admin/Alianza'),
				meta: {
					title: 'Alianzas - Financiamiento y Cofinanciamiento',
					middleware: auth
				}
			},
			{
				path: '/fyc/admin/configuraciones',
				name: 'fyc_admin_configuracion',
				component: () => import('@/apps/fyc/pages/Admin/Configuracion'),
				meta: {
					title: 'Configuración - Financiamiento y Cofinanciamiento',
					middleware: auth
				}
			}
		]
	}
]